$mobile_min: 500px;
$mobile_max: $mobile_min + 1px;
$ipad_min: 800px;
$ipad_max: $ipad_min + 1px;
$ipad2_min: 900px;
$ipad2_max: $ipad2_min + 1px;
$dekstop_min: 1000px;
$dekstop_max: $dekstop_min + 1px;

$navbar_margin: 57.5px;
