@import "device_size";

.profile-container {
  font-family: "Roboto", sans-serif;
  .profile-content {
    display: flex;
    justify-content: space-between;

    .main-profile {
      //   background-color: red;
      width: 60%;

      .text-length {
        position: absolute;
        bottom: 10px;
        right: 20px;
      }
    }

    .additional-profile {
      //   background-color: green;

      width: 35%;
    }
  }
}

.profile-container-mobile {
  font-family: "Roboto", sans-serif;
  .profile-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f9f9f9;
    border-bottom: 1px solid rgb(223, 223, 223);
  }
  .profile-body {
    position: relative;
    z-index: 1;
    .custom-form-control {
      padding: 5px 0;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid black;
      input {
        border: none;
        background-color: transparent;
      }
      input:focus-visible {
        outline: none;
      }
    }
  }
}

.modal-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

  form {
    background-color: white;
    .img-wrapper {
      height: 200px;
      width: 200px;
    }
    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
    }
  }
  .modal-header {
    button {
      border: 0;
      background: transparent;
      i {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: $mobile_min) {
  .profile-container {
    display: none;
  }
}

@media screen and (min-width: $mobile_max) {
  .profile-container-mobile {
    display: none;
  }
}
