@import "colors";
@import "device_size";
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;600;700&family=Roboto:wght@400;900&display=swap");

/*#region COLOR */
.color-blue-medsos {
  color: $blue-medsos !important;
}

.color-green-medsos {
  color: $green-medsos !important;
}

.color-transparent {
  color: transparent;
}

.color-gray {
  color: $gray;
}

.color-green {
  color: $green-medsos !important;
}

.bg-icon-ig {
  background: linear-gradient(
    45deg,
    #ffffff,
    #fcaf45,
    #f77737,
    #c13584,
    #833ab4,
    #405de6
  );
}

.bg-icon-ig {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d,
    #f56040,
    #f77737,
    #fcaf45
  );
}

.bg-green-medsos {
  background-color: $green-medsos !important;
}

.bg-blue-medsos {
  background-color: $blue-medsos !important;
}

.bg-default-blue {
  background-color: $default-blue !important;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-gray-100 {
  background-color: $gray-100 !important;
}

/*#endregion*/

.ff-roboto {
  font-family: "Roboto", sans-serif;
}

/*#region SIZE */
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-30 {
  font-size: 30px !important;
}
/*#endregion*/

// html,
// body,
// #root {
//   min-height: 100vh;
// }

body {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
  // background-color: ;
}

.custom-container {
  margin-top: $navbar_margin;
  display: flex;
  // align-items: center;
}

.img-contain {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.img-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.img-scale-down {
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}

.img-fill {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
}

a {
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

/*#region Layout */
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}

.wrapper:after {
  clear: both;
}

.text-justify {
  text-align: justify;
}

.text-underline {
  text-decoration: underline;
}

.layout-boxed .wrapper {
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}

.layout-boxed {
  background-color: #f9fafc;
}

/*#endregion*/

/*#region font Weight */

.fw-1 {
  font-weight: 900;
}

.fw-2 {
  font-weight: 800;
}

.fw-3 {
  font-weight: 700;
}

.fw-4 {
  font-weight: 600;
}

.fw-5 {
  font-weight: 500;
}

/*#endregion */

/*#region Navigation Bar */

.header-image {
  display: flex;
  margin-bottom: 0;
  border-radius: 0 10px 10px 0px;
}

.navbar-nav .nav-item.active {
  background-color: $default-green !important;
}

.navbar-nav .nav-item.active .nav-link {
  color: white !important;
}

/*#endregion */

/*#region mobile content */
.news-image {
  margin-left: 0;
  margin-right: 0;
}

.news-body {
  margin-left: 0;
  margin-right: 0;
}

.news-footer {
  padding-top: 2px;
}

.logo img {
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
}

.logo-rounded {
  background-color: $blue-medsos;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.image-search {
  position: relative;
  color: black;
  text-align: left;
}

.bottom-left {
  position: absolute;
  padding-left: 4px;
  padding-top: 2px;
  bottom: 0;
  width: 100%;
  height: 40%;
  background-color: $green-medsos;
  border-radius: 0 25px 0 0;

  display: flex;
  align-items: flex-start;
  line-height: 1;
  font-size: 10px;
}

.bottom-left small {
  margin-top: 4px;
}

.content-container-detail {
  display: flex;
  justify-content: space-between;

  .content-container {
    width: 65%;
  }

  .title-container {
    .news-title-right {
      display: flex;
      align-items: flex-end;
    }
  }
}

.comment {
  display: flex;
  padding-bottom: 20px !important;
  justify-content: space-between;
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: rgb(202, 211, 215);
    outline: 0;
    box-shadow: none;
  }
  .btn-send-comment {
    border: none;
    border-radius: 0 10px 10px 0;
    height: 100%;
    margin-left: -1px;
    background-color: rgb(202, 211, 215);
  }
  .btn-send-comment:hover {
    background-color: black;
    transition: all 0.75s ease;
    i {
      color: white;
      transition: all 0.75s ease;
    }
  }
}

.navbar-news {
  width: 100%;
  z-index: 998;
  display: flex;
}

.title-container {
  display: flex;

  .news-title {
    font-weight: bolder;
    width: 65%;
  }

  .news-title {
    .category-container {
      display: inline-block;
      // gap: 5px;
      label {
        margin: 0 2.5px;
      }
    }
  }
}

.navbar-option {
  .navbar-search {
    width: 30% !important;
    .btn-search,
    input {
      height: 35px;
    }
    .btn-search {
      padding: 5px 8px;
      border-radius: 0 5px 5px 0;
      border: none;
      border-left: none;
      background: rgb(195, 195, 195);
    }
    .btn-search:hover {
      background: $green-medsos;
    }
    input:focus {
      outline: none !important;
    }
  }

  .custom-navbar-sosmed {
    width: 250px;
    display: flex;
    justify-content: end;
    align-items: center;

    a {
      // padding: 0 5px;
      color: white;
      margin: 0 4px;
      width: 25px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    .icon-profile {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
  }
}

/*#endregion*/

/*#region identity */
.identity-container {
  padding: 10px;
  border: 1px solid #dedede;
  .img-wrapper {
    height: 80px;
    width: 80px;
  }
  li {
    padding: 5px;
    list-style: none;
  }
}
/*#endregion*/

.redaktur-details {
  display: flex;
  color: $blue-medsos;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

.news-date {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-action {
  margin: 10px 0;
  // .btn:first-child {
  //   border: none;
  // }
  // .btn:first-child:active {

  // }
  .btn {
    border: none;
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    border: none;
  }
}

@media screen and (max-width: $mobile_min) {
  .fallback-loading img {
    width: 50px;
    height: 50px;
  }

  .redaktur-details {
    display: none !important;
  }

  .actived {
    display: none !important;
  }

  .comment {
    display: none;
  }
}

@media screen and (min-width: $mobile_max) {
  .fallback-loading img {
    width: 50px;
    height: 50px;
  }

  .btn-group {
    display: none !important;
  }

  .btn-bookmark {
    display: none !important;
  }

  .news-like {
    display: none;
  }
}

@media screen and (max-width: $dekstop_min) {
  .redaksi-dekstop {
    display: none;
  }

  .redaksi-mobile {
    display: block;
  }

  .tentang-kami-dekstop {
    display: none;
  }

  .tentang-kami-mobile {
    display: block;
  }

  .fallback-loading img {
    width: 50px;
    height: 50px;
  }

  .content-container-detail {
    display: flex;
    justify-content: space-between;

    .content-container {
      width: 100%;
    }
  }

  .content-container-detail {
    display: flex;
    justify-content: space-between;

    .content-container {
      width: 100%;
    }
  }

  .right-sidebar {
    display: none;
  }

  .navbar-option {
    .custom-navbar-sosmed {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100%;

      a {
        // padding: 0 5px;
        margin: 0 4px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .navbar {
    border-bottom: 1px solid #abb8c3;
    position: sticky !important;
    top: 0;
    bottom: 10px;
    z-index: 1000;
  }

  .navbar-search {
    display: none;
  }

  .list-inline .list-inline-item {
    margin: 1px !important;
  }

  .content {
    margin-left: 1px;
  }

  .header-image {
    width: 50%;
  }

  .icon-bell {
    display: none;
  }

  .line-navbar {
    display: none;
  }

  .content-cotainer {
    width: 100% !important;
  }

  .btn-class {
    display: none;
  }

  .title-container {
    display: block;
    padding: 0 5px;
    .news-title {
      width: 100%;
    }
    .news-title-right {
      display: none;
    }
  }
}

@media screen and (min-width: $dekstop_max) {
  .redaksi-dekstop {
    display: block;
  }

  .redaksi-mobile {
    display: none;
  }

  .tentang-kami-dekstop {
    display: block;
  }

  .tentang-kami-mobile {
    display: none;
  }

  .fallback-loading img {
    width: 100px;
    height: 100px;
  }

  .navbar-news {
    display: none;
  }

  .icon-live {
    display: none !important;
  }

  .navbar-expand-lg {
    justify-content: none;
  }

  .title-news {
    display: none;
  }
}

.custom-accordion {
  cursor: pointer;
  padding: 16px 20px;
}

.btn-load-more {
  border: 1px solid black;
  background: black;
  color: white;
  transition: 0.5s all;
}

.btn-load-more:hover {
  border: 1px solid black;
  background: white;
  color: black;
}

.ff-arial {
  font-family: Arial !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
