@import "colors";
@import "device_size";

// *::-webkit-scrollbar {
//   display: none;
// }

.date,
.creator {
  font-family: "Roboto", sans-serif;
}

.creator span:hover {
  color: blue;
}

.news-container-header {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: calc(46.8px);
  padding: 5px 0;
  background: white;
}

.news-container {
  .news-body {
    .description {
      font-family: Arial !important;
    }
  }
}

.news-header {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.divider {
  height: 1px;
  width: auto;
  background: rgb(192, 192, 192);
}

.divider-2 {
  height: 2px;
  width: auto;
  background: black;
}

.divider-vertical-2 {
  width: 2px;
  height: auto;
  background: black;
}

@media screen and (min-width: $mobile_max) {
  .content-container-detail {
    gap: 10px;
  }

  .news-container {
    .news-image {
      height: 470px;
    }
  }

  .content-container {
    // height: calc(100svh - 60px);
    // padding-bottom: 60px;
    overflow-y: auto;
  }
}

@media screen and (max-width: $mobile_min) {
  .content-container::-webkit-scrollbar {
    display: none;
  }

  .non-active {
    display: none !important;
  }

  .content-container {
    // height: calc(100svh - 50px);
    // overflow-y: auto;
  }
}

input[type="checkbox"]:checked + label,
.Checked + label {
  background-color: $default-green !important;
  color: white !important;
}

@media screen and (max-width: $ipad_max) {
  .news-item {
    .description {
      padding-top: 10px !important;
    }
  }

  .news-grid-container,
  .news-grid-container-skeleton {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .loading-data {
    grid-column: 1/2;
  }

  .content-scroll {
    overflow-y: scroll;
  }

  .news-container,
  .news-reel-container {
    padding: 10px 5px 0 5px;
  }

  .news-header {
    .logo-wrapper {
      width: 50px;
      max-height: 56px;
    }

    .news-carousel-container {
      img {
        height: 150px;
      }

      .description {
        width: 100%;
        font-size: 16px !important;
      }
    }
  }

  .non-active {
    display: block !important;
  }

  .news-header {
    .logo-wrapper {
      width: 50px;
      max-height: 56px;
    }

    .news-carousel-container {
      img {
        height: 150px;
      }

      .description {
        width: 100%;
        font-size: 16px !important;
      }
    }
  }
}

.news-grid-container,
.news-grid-container-skeleton {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.loading-data {
  grid-column: 1/4;
}

.filter-news-container {
  .loading-data {
    grid-column: 1/4;
  }
}

.content-scroll::-webkit-scrollbar {
  display: none;
}

.content-container-custom {
  // transition: all 0.75s;
  display: block;
  width: 100%;
  position: relative;
  // width: calc(100% - 200px);
  background: #f9f9f9 !important;

  .highlight-container,
  .news-container {
    .news-header {
      text-decoration: underline;
    }
  }
  .highlight-container {
    border-bottom-left-radius: 20px;
    background: rgb(25, 142, 1);
    background: linear-gradient(
      0deg,
      rgba(25, 142, 1, 1) 15%,
      rgba(55, 156, 0, 1) 35%,
      rgba(136, 180, 11, 1) 65%,
      rgba(166, 186, 19, 1) 85%
    );
    .highlight-carousel-container {
      img {
        max-height: 300px;
      }

      .description {
        width: 100%;
        font-size: 12px;
        color: white;
      }
    }
  }

  .news-carousel-container,
  .news-reel-carousel-container {
    .img-wrapper {
      overflow: hidden;
      border-radius: 0.375rem;
    }
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.1);
    }
    .description {
      inline-size: 150px;
      overflow-wrap: break-word;
    }
  }

  .news-container {
    .logo-wrapper {
      width: 20px;
      height: 20px;
    }

    // .news-image {
    //   height: 470px;
    // }

    .news-carousel-container {
      .description {
        width: 100%;
        font-size: 16px !important;
      }
    }
  }

  .category-news-container {
    .news-item {
      .img-wrapper {
        overflow: hidden;
        border-radius: 0.375rem;
        width: 100%;
        max-width: 400px;
        height: 100%;
        img {
          transition: all 0.5s;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
      .news-title:hover {
        color: #979797;
      }
    }
    .nav {
      --bs-nav-link-color: black;
    }
    .form-control:focus {
      box-shadow: none;
    }
    .nav-tabs {
      --bs-nav-tabs-border-width: none;
      --bs-nav-tabs-border-radius: none;
      --bs-nav-tabs-link-hover-border-color: none;
      --bs-nav-tabs-link-active-color: none;
      --bs-nav-tabs-link-active-border-color: none;
      border-bottom: none;
      .nav-item {
        font-weight: bold;
        .active {
          background-color: $blue-medsos;
          color: white;
        }
      }
    }
  }

  .news-reel-container {
    .logo-wrapper {
      width: 30px;
      height: 30px;
    }

    .news-reel-carousel-container {
      position: relative;
      height: 300px;

      .img-wrapper {
        position: absolute;
        height: 100%;

        img {
          max-height: 300px;
        }
      }

      .description {
        width: 100%;
        box-shadow: 0 -10px 3px rgba(0, 0, 0, 0.4);
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.7) 40%,
          rgba(0, 0, 0, 0.4) 100%
        );
        color: white;
        position: absolute;
        bottom: 0;
        z-index: 999;
        font-size: 16px !important;
      }
    }
  }
}

@media screen and (min-width: $mobile_min) {
  .news-container,
  .news-reel-container {
    padding: 10px 10px 0 10px;
  }

  .news-container-header {
    display: none;
  }
  .news-item {
    .description {
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width: $mobile_max) {
  .news-item {
    .description {
      padding-top: 10px !important;
    }
  }

  .news-grid-container,
  .news-grid-container-skeleton {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }

  .loading-data {
    grid-column: 1;
  }

  .content-scroll {
    overflow-y: scroll;
  }

  .non-active {
    display: block !important;
  }
}

.nav-containers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  button {
    padding: 10px 15px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    color: black;
  }
}

.badge-category {
  font-size: 12px;
  font-weight: bold;
  border-radius: 6px;
  padding: 2px 8px;
  background: rgb(0, 0, 0);
  color: white;
}
