@import "colors";
@import "device_size";

.sidebar {
  text-align: center;
  height: calc(100vh - 65px);
  // transition: all 0.75s ease 0s;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: $navbar_margin;
  overflow-y: scroll;
  a {
    height: 60px;
    text-decoration: none;
    img {
      width: 20px;
      height: 20px;
    }
    label {
      color: $blue-medsos;
      font-weight: bold;
    }
  }

  .active {
    background-color: #145c78 !important;
    label {
      color: $green-medsos !important;
    }
  }

  a:hover {
    background-color: rgb(225, 225, 225) !important;
    label {
      color: $green-medsos !important;
    }
  }
}

.sidebar::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li > a {
  padding: 8px;
  display: block;
  font-size: 12px;
  color: $blue-medsos;
  text-decoration: none;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 999998;
  transition: all 1s ease-in-out;
  .sidebar-background {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    transition: all 1s ease-in-out;
  }
  .sidebar-content {
    overflow: scroll;
    width: 85%;
    max-width: 310px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgb(255, 255, 255);
    transition: all 0.75s ease-in-out;
    a {
      text-decoration: none;
      img {
        width: 27px;
      }
      label {
        color: $blue-medsos;
        font-weight: bold;
      }
      .active {
        background-color: $blue-medsos !important;
        label {
          color: $green-medsos !important;
        }
      }
    }
    .accordion-item {
      border: none;
      border-bottom: 1px solid rgb(209, 209, 209);
    }
    .accordion-body {
      padding-left: 20px;
      .btn {
        padding: 5px 10px;
      }
    }
    .accordion-button {
      width: 100%;
    }
    .accordion-button:focus {
      border: none;
      box-shadow: none;
    }
    .accordion-button:not(.collapsed) {
      color: black;
      background-color: white;
      box-shadow: none;
    }
    .btn {
      border-radius: 0;
    }
    .btn:hover {
      color: white;
      background: $blue-medsos;
    }
    .btn:active {
      border: none;
    }

    .active {
      background-color: $blue-medsos !important;
      label {
        color: $green-medsos !important;
      }
    }
  }
  .sidebar-content::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 801px) {
  .sidebar {
    display: none !important;
  }
}
