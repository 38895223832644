@import "colors";
@import "device_size";

.kontak-container {
  .kontak-content-container {
    gap: 20px;
    position: relative;
    z-index: 1;
  }

  .img-top {
    // height: 250px;
    // width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .left {
    gap: 20px;
    font-style: italic;
  }
}

@media screen and (min-width: $ipad_max) {
  .kontak-container {
    .kontak-content-container {
      margin-left: 20px;
    }
    .center {
      display: block;
    }
    .first-center {
      display: none;
    }
  }
}

@media screen and (max-width: $ipad_min) {
  .kontak-container {
    .kontak-content-container {
      margin-left: 0;
      margin: 0 10px;
    }
    .left {
      .quotes {
        text-align: center;
      }
      .contact {
        justify-content: center;
      }
    }
    .first-center {
      display: block;
    }
    .center {
      display: none;
    }
    .img-top {
      display: none;
    }
  }
}
