@import "colors";
@import "device_size";

.filter-sticky {
  position: sticky;
  top: $navbar_margin;
  z-index: 2;
  background: white;
}

.filter-container {
  z-index: 2;
  font-size: 12px;
  overflow-x: scroll;
  width: 100%;
  gap: 8px;

  .btn-filter {
    position: relative;
    height: min-content;
    padding: 5px 0;

    label,
    input {
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-width: fit-content;
      padding: 8px 12px;
      color: white;
      font-weight: bold;
    }

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      opacity: 0.01;
      cursor: pointer;
    }

    label:hover {
      color: white;
      background-color: $green-medsos !important;
    }

    input[type="checkbox"]:checked + label,
    .Checked + label {
      background-color: hover;
      color: black;
    }

    input[type="radio"]:checked + label,
    .Checked + label {
      color: white !important;
      background-color: $green-medsos !important;
    }
  }

  .btn-filter-checkbox {
    label {
      width: min-content;
      font-size: 14px;
      text-align: center;
      line-height: 1;
      display: block;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      background: rgb(139, 139, 139) !important;
    }
  }
  .btn-filter-radio {
    label {
      width: min-content;
      font-size: 14px;
      text-align: center;
      line-height: 1;
      display: block;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      background: rgb(139, 139, 139) !important;
    }
  }
}

.filter-container::-webkit-scrollbar {
  display: none;
  scrollbar-width: none; /* Firefox 64 */
}

.filter-news-container {
  height: calc(100vh - 220px);
  // padding-bottom: 20px;
  overflow: auto;
}

.filter-data-container {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  z-index: 1;
  .filter-data-item {
    position: relative;
    height: 159px;
    .img-wrapper {
      position: absolute;
      height: 100%;

      img {
        height: 100%;
      }
    }

    .description {
      min-height: 40px;
      border-radius: 0 20px 0 0;
      width: 100%;
      background-color: rgb(135 217 22);
      position: absolute;
      bottom: 0;
      z-index: 999;
      font-size: 12px;
      line-height: 1;
    }
  }
}

@media screen and (min-width: $ipad-max) {
  .filter-container {
    display: block;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: $navbar_margin;
  }
}
