@import "colors";
@import "device_size";

.comment-slider-container::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.comment-slider-container {
  max-height: calc(100svh - 102px - 131px);
  overflow-y: scroll;
  i {
    font-size: 20px;
  }
  .btn-like {
    background: transparent;
    border: none;
    i {
      color: black;
    }
    i:hover {
      color: $blue-medsos;
    }
  }
  .btn-like {
    background: transparent;
    border: none;
  }
  .my-comment {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
  }
  .my-comment:focus {
    outline: none;
  }
  .btn-batal,
  .btn-balas {
    border-radius: 15px;
    color: white;
    font-weight: bold;
  }
  .btn-batal,
  .balas-active {
    background-color: rgb(174, 174, 174);
  }
  .btn-batal:hover,
  .balas-active:hover {
    background-color: rgb(0, 0, 209);
  }
  .my-comment {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid black;
  }
  .my-comment:focus {
    outline: none;
  }
}

.input-comment-container-fixed {
  z-index: 999999;
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100vw;
  box-shadow: 0 0.5px 5px 0.1px #bebebe;
  padding: 10px 15px;
  background-color: white;
  .img-wrapper {
    height: 40px;
    width: 40px;
  }
  input {
    border: none;
    padding-left: 10px;
  }
  input:focus-visible {
    outline: none;
  }
}

.input-comment-container {
  padding: 15px 30px 30px 15px;
  position: absolute;
  width: 100%;
  box-shadow: 0 0.5px 5px 0.1px #bebebe;
  background-color: white;
  bottom: 0;
  left: 0;
  .img-wrapper {
    height: 40px;
    width: 40px;
  }
  input {
    border: none;
    border-bottom: 1px solid black;
    padding-left: 10px;
  }
  input:focus-visible {
    outline: none;
  }
}

.input-comment-container-fixed {
  z-index: 999999;
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100vw;
  box-shadow: 0 0.5px 5px 0.1px #bebebe;
  padding: 10px 15px;
  background-color: white;
  .img-wrapper {
    height: 40px;
    width: 40px;
  }
  input {
    border: none;
    padding-left: 10px;
  }
  input:focus-visible {
    outline: none;
  }

  .btn-batal,
  .btn-balas {
    border-radius: 15px;
    color: white;
    font-weight: bold;
  }
  .btn-batal,
  .balas-active {
    background-color: rgb(174, 174, 174);
  }
  .btn-batal:hover,
  .balas-active:hover {
    background-color: rgb(0, 0, 209);
  }
}

.comment-right {
  position: relative;
  padding: 20px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  border-radius: 0 20px 20px 0;
  transition: all 0.75s ease 0s;
}

.comment-right::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

@media screen and (min-width: $mobile_min) {
  .input-comment-container-fixed {
    display: none;
  }
}

@media screen and (max-width: $mobile_max) {
  .comment-dekstop {
    display: none;
  }

  .comment-slider-container {
    max-height: calc(100svh - 102px - 71px);
    padding: 0 10px;
  }
}
