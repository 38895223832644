@import "colors";
@import "device_size";

.pedoman-siber-container {
  z-index: 0;
}

@media screen and (min-width: $ipad_max) {
  .pedoman-siber-container {
    background-image: url("../images/background/Background_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px;
    .background {
      background-color: $blue-medsos !important;
      color: white;
      p,
      li {
        font-size: 0.75rem;
      }
    }
  }
}

@media screen and (min-width: $ipad2_max) {
  .pedoman-siber-container {
    background-image: url("../images/background/Background_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px;
    .background {
      background-color: $blue-medsos !important;
      color: white;
      p,
      li {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: $ipad_min) {
  .pedoman-siber-container {
    background-color: white;
    h3 {
      color: $blue-medsos;
    }

    p,
    li {
      font-size: 0.75rem;
      color: black;
    }
  }
}
