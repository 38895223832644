@import "colors";
@import "device_size";

.tentang-kami-container {
  .top-container {
    margin-top: 20px;
  }
  h1 {
    font-size: 50px;
  }
  .company {
    .img-wrapper {
      width: 350px;
    }
  }
  .background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: $ipad-max) {
  .tentang-kami-container {
    .top-container {
      display: flex;
      flex-direction: row;
      .map {
        .img-wrapper {
          height: 290px;
        }
      }
    }
    .bottom-container {
      display: none;
    }
    .about-container {
      padding: 20px 0 0 0;
      .about-content {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
  }
}

@media screen and (max-width: $ipad-min) {
  .tentang-kami-container {
    .top-container {
      display: none;
    }
    .bottom-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .about-container {
      padding: 20px 0;
      .about-content {
        border-radius: 1rem;
      }
    }
  }
}
