@import "colors";
@import "device_size";

.redaksi-container {
  .img-top-right {
    height: 250px;
    width: 250px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
  .foto {
    position: relative;
    .triangle {
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-right: 25px solid $green-medsos;
      border-bottom: 25px solid transparent;
      position: absolute;
      top: 40%;
      right: 0;
    }
  }
  .desc {
    .divider {
      height: 5px !important;
    }
  }
}

@media screen and (max-width: $ipad_max) {
  .img-top-right {
    display: none;
  }
}
