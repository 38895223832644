@import "device_size";

.footer {
  z-index: 99999;
  width: 100%;
  height: 50px;
  /* padding-left: 10px; */
  line-height: 50px;
  background: #187194;
  color: #fff;
  position: fixed;
  bottom: 0px;
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding-left: 30px;
  padding-right: 30px;

  .footer-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    column-gap: 50px;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 10px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (min-width: $ipad_max) {
  .footer {
    display: none;
  }
}
