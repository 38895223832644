.bookmark-container {
  padding: 0 10px;
  overflow-y: auto;
  .bookmark-item-container {
    display: grid;
    gap: 3px;

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr 1fr;
      // grid-template-rows: 1fr 1fr;
    }

    @media screen and (max-width: 800px) {
      padding-bottom: 55px;
    }

    @media screen and (min-width: 501px) {
      grid-template-columns: 1fr 1fr 1fr;
      // grid-template-rows: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }

  .news-item {
    .img-wrapper {
      overflow: hidden;
      img:hover {
        scale: 1.3;
        transition: all 0.5s;
      }
    }
  }
}
