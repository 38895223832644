@import "colors";
@import "device_size";

.right-sidebar {
  height: 100%;
  width: 35%;
  // margin-left: 3px !important;
  position: sticky;
  top: $navbar_margin;
  .news-title-right {
    font-weight: bold;
    width: 35%;
  }

  .right-sidebar-content {
    font-size: 12px;

    img {
      border-radius: 5px;
    }

    .right-sidebar-item {
      border-radius: 10px;
      color: black;
      // max-height: 150px;
    }

    .right-sidebar-item:hover {
      background-color: $blue-medsos;
      color: white;
      transition: all 0.5s;
    }
  }

  .img-wrapper {
    width: 200px !important;
    height: 131px !important;
  }
}

// @media screen and (max-width: 801px) {
//   .content-container-detail {
//     display: block;
//     // justify-content: space-between;
//   }
// }
