@import "colors";
@import "device_size";

.nav-container {
  height: $navbar_margin;
  justify-content: space-between;
  box-shadow: 0 0 3px #a1bc04;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.flex-div {
  padding: 0 5px;
  display: flex;
  align-items: center;

  .nav-right {
    img {
      width: 25px;
      margin-right: 25px;
    }
    .user-icon {
      width: 35px;
      height: 35px;
      margin-right: 0px;
      border-radius: 50%;
    }
  }

  .nav-left {
    .menu-icon {
      width: 22px;
      margin-right: 25px;
    }
    .logo {
      width: 130px;
    }
  }

  .nav-middle {
    position: relative;
    .mic-icon {
      width: 16px;
    }
    input {
      font-size: 12px;
      height: 16px;
    }

    .search-box {
      border-radius: 25px;
      border: 1px solid #ccc;
      margin-right: 15px;
      padding: 8px 12px;
      /* border-radius: 25px; */
    }

    .search-box input {
      width: 400px;
      border: 0;
      outline: 0;
      background: transparent;
    }

    .search-box img {
      width: 15px;
    }

    .search-list {
      position: absolute;
      overflow-y: scroll;
      max-height: 300px;
      top: 33.3px;
      left: 0;
      transform: translateX(5%);
      background-color: #fff;
      width: calc(100% - 15px - 23px - 30px);
      z-index: 9999;
      li {
        width: 100%;
        cursor: pointer;
        list-style: none;
      }
      li:focus-visible {
        background-color: #ccc;
        outline: none;
      }
      li:hover {
        background-color: #ccc;
        outline: none;
      }
    }
  }
}

// @media (max-width: $mobile_min) {
//   .nav-right .user-icon {
//     display: none;
//   }
// }

@media (max-width: $dekstop_min) {
  // .nav-right .user-icon {
  //   display: block;
  //   width: 30px;
  // }

  .nav-middle .search-box {
    width: 100px;
    display: none;
  }

  .nav-middle .mic-icon {
    display: none;
  }

  .nav-left .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-middle .search-box {
    width: 100px;
    display: none;
  }

  .nav-middle .mic-icon {
    display: none;
  }
}

@media (min-width: $mobile_max) {
  .nav-right {
    .hamburger {
      display: none;
    }
  }
}

@media (max-width: $mobile_min) {
  .flex-div {
    padding: 0 0 0 5px;
  }
  .nav-left .hamburger {
    display: none;
  }

  .active-1000 {
    display: none;
  }

  .nav-right {
    .hamburger {
      display: flex;
    }
  }
}
