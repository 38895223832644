.share-container {
  // background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 99999;
  .share-background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.432);
  }
  .share-item-container {
    position: absolute;
    background-color: white;
    width: 90%;
    max-width: 500px;
    input {
      width: 85%;
      max-width: 245px;
    }
  }
}
