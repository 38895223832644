@import "colors";
@import "device_size";

.main-footer-container {
  margin-top: 20px;
  border-top: 1px solid $green-medsos;
  background-color: #ffffff;
  .section-title {
    margin-bottom: 5px;
  }
}

@media (max-width: $mobile_max) {
  .main-footer-container {
    img {
      margin-bottom: 20px;
    }
  }

  .footer-section {
    margin-bottom: 20px;
  }
}
