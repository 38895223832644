@import "device_size";

.login-container {
  max-width: 400px;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  z-index: 9999;
  .img-wrapper {
    // height: 60px;
    width: 250px;
  }
  form {
    border-radius: 15px;
    .sign-up {
      cursor: pointer;
    }
    input {
      padding: 10px 10px;
    }
    .btn {
      padding: 10px 5px;
      font-weight: bold;
    }

    .policy-desc {
      font-size: 12px;
    }

    .or-divider {
      position: relative;
      text-align: center;
      .divider {
        height: 2px;
        width: 100%;
        background: black;
      }
      label {
        position: absolute;
        font-size: 20px;
        border-radius: 100px;
        background-color: white;
        padding: 5px;
        transform: translate(-50%, -50%);
      }
    }

    .btn-show {
      border: 1px solid #dee2e6;
      border-left: 0;
      border-radius: 0 0.25rem 0.25rem 0;
      width: 30px;
    }

    #username {
      border-radius: 5px;
    }
    #email {
      border-radius: 5px;
    }
    #password {
      border-radius: 5px 0 0 5px;
    }

    .form-control:focus {
      box-shadow: none;
      border-color: black;
    }

    .form-control:focus + button {
      box-shadow: none;
      border-color: black;
    }

    .btn-google {
      border: 1px solid black;
      background: white;
      z-index: 9999;
      .divider {
        width: 1px;
        height: 25px;
        margin: 0 5px;
        border-left: 1px solid rgb(172, 172, 172);
      }
      .google-login {
        font-weight: 100;
      }
      .img-wrapper {
        height: 25px;
        width: 40px;
      }
    }

    .btn-google:hover {
      background: rgb(0, 0, 0);
      color: white;
      .divider {
        height: 25px;
        border-left: 1px solid rgb(172, 172, 172);
      }
    }
  }
}
@media screen and (min-width: $dekstop_max) {
  .login-container {
    form {
      border: 1px solid rgb(215, 215, 215);
    }
  }
}
