.bottom-sheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
  z-index: 99998;
  .card {
    border: none !important;
  }
  .content {
    .header {
      padding-top: 20px;
      .drag-icon {
        cursor: grab;
        user-select: none;
        padding: 15px;
        margin-top: -15px;
        span {
          height: 4px;
          width: 40px;
          display: block;
          background: #c7d0e1;
          border-radius: 50px;
        }
      }
    }
  }
}
.bottom-sheet.show {
  opacity: 1;
  pointer-events: auto;
}
.bottom-sheet .sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: #000;
}
.bottom-sheet .content {
  width: 100%;
  position: relative;
  background: #fff;
  max-height: 100vh;
  max-width: 1150px;
  height: 0;
  transform: translateY(100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  transition: 0.3s ease;
}
.bottom-sheet.show .content {
  transform: translateY(0%);
}
.bottom-sheet.dragging .content {
  transition: none;
}
.bottom-sheet.fullscreen .content {
  border-radius: 0;
  overflow-y: hidden;
}
.bottom-sheet .header {
  display: flex;
  justify-content: center;
}
.bottom-sheet .body {
  height: 100%;
  overflow-y: auto;
  padding: 15px 0 40px;
  scrollbar-width: none;
}
.bottom-sheet .body::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 500px) {
  .bottom-sheet {
    display: flex;
  }
}

@media screen and (min-width: 501px) {
  .bottom-sheet {
    display: none;
    .navbar-news {
      display: flex;
    }
  }
}
