@import "colors";

.tiktok-background {
  background-color: rgba(0, 0, 0, 0.753);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.tiktok-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9997;
  transition: all 0.75s ease 0s;
  .swiper {
    width: 100%;
    height: 100%;
  }
  .tiktok-background-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
  }
  .tiktok-menu-top {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    );
    a {
      z-index: 9999;
    }
    i {
      font-size: 35px;
    }
  }
  a {
    cursor: pointer;
    color: white;
  }
  .img-scroll {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
  }

  .tiktok-bottom {
    position: absolute;
    // padding-top: 20px;
    bottom: -1px;
    z-index: 9999;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 55%,
      rgba(0, 0, 0, 0.35) 75%,
      rgba(0, 0, 0, 0.25) 90%,
      rgba(0, 0, 0, 0.05) 100%
    );

    .tiktok-tittle {
      padding: 6px;
    }
    .tiktok-menu {
      text-align: center;

      a {
        display: inline-block;
        // background-color: $green-medsos;
        border-radius: 50%;
        padding: 6px;
        margin: 3px 0px;

        img {
          width: 100%;
          border-radius: 25%;
          display: block;
        }
      }
      a:hover {
        background-color: $blue-medsos !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .tiktok-container {
    max-height: 100%;
    max-width: 100%;
    video {
      border-radius: 0;
    }
  }
}

@media screen and (min-width: 501px) {
  .tiktok-container {
    max-height: 600px;
  }
}
