@import "colors";

.dropdown-menu-container {
  .dropdown-menu-background {
    display: flex;
    overflow-x: scroll;
    // width: 1440px;
  }
  .btn-category,
  .btn-subcategory {
    position: relative;

    label,
    input {
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: $blue-medsos !important;
      font-weight: bold;
      background: rgb(229, 229, 229) !important;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0.01;
      z-index: 100;
      cursor: pointer;
    }

    label:hover {
      color: white;
      background-color: $green-medsos !important;
    }

    input[type="radio"]:checked + label,
    .Checked + label {
      color: white !important;
      background-color: $green-medsos !important;
    }

    label {
      width: 150px;
      padding: 5px;
      text-align: center;
      line-height: 1;
      display: block;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      z-index: 90;
    }
  }

  .dropdown-menus {
    .filter-container {
      overflow-x: auto;
      .btn-filter {
        height: 100%;
        width: 100%;
        padding: 0;
        label {
          width: 150px;
        }
      }
    }
  }
  // label {
  //   box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  //   color: #fff;
  //   padding: 12px 25px;
  //   border: none;
  //   cursor: pointer;
  //   z-index: 3;
  //   transition: all 0.75s ease-in-out;
  //   background: $blue-medsos;
  // }

  // ul {
  //   list-style: none;
  //   padding: unset;
  //   margin: unset;
  // }

  // label {
  //   width: 150px;
  //   padding: 12px 25px;
  //   height: 100%;
  //   border-left: 1px solid white;
  //   background: $blue-medsos;
  //   color: white;
  //   transition: all linear 0.3s;
  // }

  // label:hover {
  //   background: $green-medsos;
  //   cursor: pointer;
  // }

  // .dropdown-menus {
  //   background: #fff;
  //   box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  //   transition: all 0.5s ease-in;
  //   z-index: 2;
  // }
}
.dropdown-menu-background::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
