.advertisement-scroll-bar {
  display: flex;
  flex-direction: column;

  .advertisement-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .logo,
    .text,
    .options {
      margin-right: 10px;
    }
  }

  .advertisement-body {
    padding: 10px;
  }

  .text {
    flex-grow: 1;
  }

  .advertisement-image {
    width: 100% !important;
    max-height: 100px !important;
  }
}

.advertisement-full-block {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  button {
    border: 0;
    border-radius: 25px;
    background: rgb(175, 0, 0);
    margin-right: 10px;
    i {
      font-size: 20px;
      color: white;
    }
  }
}

.advertisement-pop-up {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999998;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  button {
    border: 0;
    border-radius: 25px;
    background: rgb(175, 0, 0);
    margin-right: 10px;
    i {
      font-size: 20px;
      color: white;
    }
  }
}
