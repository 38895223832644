$default-blue: rgb(19, 86, 168);
$default-green: rgb(106, 168, 19);
$default-green-2: rgb(115, 185, 18);
$gray: rgb(164, 164, 164);
$gray-100: #e2e2e2;
$blue-medsos: rgb(0, 101, 142);
$blue-fb: #3b5998;
$green-medsos: #a1bc04;
$searchbar-text: #777777;
$searchbar: #c1c1c1;
